import React, { useState ,useEffect} from "react";

import facebook from "../Images/facebook-f.webp";
import instagram from "../Images/instagram.webp";
import linkedin from "../Images/linkedin.webp";
import twitter from "../Images/whiteTwitter.webp";
import abcIT from "../Images/abcIT.webp";
import abcMartech from "../Images/talkcmo1.webp";
import abcDev from "../Images/TalkDev.webp";
import abcFin from "../Images/martech-logo.webp";
import SubFintech from "../Images/FintechNew.webp";
import newss from "../Images/fintech.gif";
import abcEt from "../Images/enterpriseLogo.webp";
import "../Styles/Modal.css";
import { Container, Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
// import TalkFintech from "../Images/TalkFintechnewlog.webp";
import TalkFintech from "../Images/ttlogo.png";
import "../Styles/Content.css";
import { API_ROOT } from "../apiconfig";

// import newlogo from "../Images/rbg-fintechLogo.webp"
import silverLaptopAP from "../Images/silverLaptopAP.webp"

import "../Styles/Footer.css";

export function FooterComp() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [policyText, setPolicyText] = useState(
    "*By clicking on the Submit button, you are agreeing with the Privacy Policy with Talk Fintech."
  );
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail();
  };

  const handleEmailBlur = () => {
    validateEmail(email);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const resetForm = () => {
    setEmail("");
    setIsValidEmail(true);
    setPolicyText(
      "*By clicking on the Submit button, you are agreeing with the Privacy Policy with Talk Fintech."
    );
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (validateEmail()) {
      try {
        const response = await fetch(`${API_ROOT}/api/subscribe/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {

          resetForm(); 
          setPolicyText("Thank you for subscribing ✅");

          
          setTimeout(() => {
            resetForm();
          }, 5000);
        } else {
     
          console.error("Subscription failed");

         
          if (response.status === 404) {
          
            setPolicyText("This email is already subscribed ❌");
          }
        }
      } catch (error) {
        console.error("Error sending subscription request:", error);
      }
    } else {
      console.log("Invalid email");
    }
  };

  return (
    <>

      <footer className="footerPadding text-black">
        <div className="container container-max">
          <div className="row text-black mt-2">
            <div className="col-md-3 col-12 mt-3">
              <source srcSet={TalkFintech} type="image/webp" />
              <img
                className="FootLogo"
                src={TalkFintech}
                // srcSet={`${newlogo} 150w, ${newlogo}@2x 300w`}
                sizes="(max-width: 150px) 150px, 300px"
                alt="TalkFintech Logo"
                loading="lazy"
                width="180"
                height="60"
              />
              <div className="textdata mt-4">
                <p className="mt-1 just-text text-black">
                  A Peer Knowledge resource - By the CXO, For the CXO
                </p>
                <p className="mt-3 just-text text-black">
                  Expert inputs on challenges, triumphs, and innovative
                  solutions from corporate Movers and Shakers in the global
                  Leadership space to add value to Business decision-making.
                </p>
                <div className="mt-3">
                  <a
                    className="fw-bold hoverHead text-black mt-3 a-tag"
                    href="mailto: media@talkfintech.com"
                  >
                    Media@TalkFintech.com
                  </a>
                </div>
                <div className="mt-3">
                  <a
                    className="fw-bold hoverHead text-black a-tag"
                    href="mailto: sales@talkfintech.com"
                  >
                    Sales@TalkFintech.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12 mt-3">
              <h6 className="h5 fw-bold border-bottom">About</h6>
              <a className="a-tag " href="/about">
                <p className=" text-black mt-3 border-bottom hoverHead">About Us</p>
              </a>

              <a className="a-tag" href="/contact-us">
                <p className=" text-black mt-3 border-bottom hoverHead">Contact Us</p>
              </a>

              <div className=" text-black mt-3 border-bottom">
                <p
                  className="text-black subclickbtn hoverHead"
                  onClick={handleShow}
                >
                  Subscribe
                </p>
              </div>

              <div className="subscribePopUp">
              <Modal show={show} className="subscribePopUp">
                <Modal.Header
                  onClick={handleClose}
                  closeButton
                  style={{ backgroundColor: "#309b65" }}
                >
                  <img
                    src={SubFintech}
                    alt="TalkCMO Banner"
                    className="subslogo"
                  />
                </Modal.Header>
                <Container className="popupBack" style={{ height: "auto" }}>
                  <Row>
                    <Col md={5} className="laptopmImgback">
                      <img
                        className="DesktopResponsive"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        src={silverLaptopAP}
                        alt="Silver Laptop"
                      />
                    </Col>
                    <Col md={7}>
              <div style={{ textAlign: "center" }}>
                <img
                  className="mt-1"
                  style={{
                    width: "60%",
                    borderRadius: "10px",
                  }}
                  src={newss}
                  alt="Newsletter Banner"
                />
              </div>

              <Form
                className="px-3"
                controlId="emailInput"
                onSubmit={handleSubmit}
              >
                <Form.Group
                  className="px-3 mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email ID"
                    onBlur={handleEmailBlur}
                    autoComplete="off"
                    aria-label="email"
                    data-testid="TextInput"
                    className={`py-3 ${isValidEmail ? "" : "is-invalid"}`}
                    value={email}
                    onChange={handleEmailChange}
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                  />
                  <div
                    className="mt-2 px-2 fw-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {policyText}
                  </div>

                  {!isValidEmail && (
                    <div className="invalid-feedback">
                      Please enter a valid email address.
                    </div>
                  )}
                </Form.Group>
                <Modal.Footer className="start">
                  <button className="SubBtn" type="submit">
                    Submit
                  </button>
                </Modal.Footer>
              </Form>
            </Col>
                  </Row>
                </Container>
              </Modal>
            </div>

              <a href="/sitemap" className="text-decoration-none">
                <p className=" text-black mt-3 border-bottom hoverHead ">Sitemap</p>
              </a>
            </div>
            <div className="col-md-3 col-12 mt-3">
              <h6 className="h5 fw-bold border-bottom">Policies</h6>

              <a className="a-tag" href="/privacy-policy">
                <p className=" text-black mt-3 border-bottom hoverHead">
                  Privacy Policy
                </p>
              </a>

              <a className="a-tag" href="/opt-out-form">
                <p className=" text-black mt-3 border-bottom hoverHead">
                  Do Not Sell My Information
                </p>
              </a>
            </div>
            <div className="col-md-3 col-12 mt-3 followPadding">
              <h6 className="h5 fw-bold border-bottom">Follow us</h6>

              <div className="d-flex mt-4">

                <div className="mainDiv">
                  <div>
                  <a href="https://www.facebook.com/talkfintech/">
                    <div className="bg-black">
                   
                    <source srcSet={facebook} type="image/webp" />
                    <img
                      src={facebook}
                      alt="facebook"
                      defer
                    />
                 
                    </div>
                    </a>
                  </div>
                  <div>
                  <a href="https://www.instagram.com/talk.fintech/">
                    <div className="bg-black">
                   
                    <source srcSet={instagram} type="image/webp" />
                    <img                      
                      src={instagram}
                      alt="instagram"
                      loading="lazy"
                    />
                 
                    </div>
                    </a>
                  </div>
                  <div>
                  <a href="https://x.com/talk_fintech">
                    <div className="bg-black">
                   
                    <img
                      src={twitter}
                      alt="twitter img"
                      loading="lazy"
                      className="ximage"
                    />
                 
                    </div>
                    </a>
                  </div>
                  <div>
                    <a href="https://www.linkedin.com/company/talkfintech">
                    <div className="bg-black">
                    <source srcSet={linkedin} type="image/webp" />
                    <img
                      src={linkedin}
                      alt="linkedin"
                      loading="lazy"
                    />
                    </div>
                  </a>
                  </div>
                </div>


                {/* <div className="facebookImg">
                  <a href="https://www.facebook.com/talkfintech/">
                    <source srcSet={facebook} type="image/webp" />
                    <img
                      className="fIMG"
                      src={facebook}
                      alt="facebook"
                      defer
                      width="640"
                      height="360"
                    />
                  </a>
                </div>
                <div className="facebookImg">
                  <a href="https://www.instagram.com/talk.fintech/">
                    <source srcSet={instagram} type="image/webp" />
                    <img
                      className="iIMG"
                      src={instagram}
                      alt="instagram"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="facebookImg">
                  <a href="https://x.com/talk_fintech">
                    <img
                      className="xIMG"
                      src={twitter}
                      alt="twitter img"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="facebookImg">
                  <a href="https://www.linkedin.com/company/talkfintech">
                    <source srcSet={linkedin} type="image/webp" />
                    <img
                      className="iIMG"
                      src={linkedin}
                      alt="linkedin"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                </div> */}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <h6 className="text-center  h5 fw-bold">Other Publications</h6>
            </div>

            <div className="justify-content-center text-center otherPublic">
              <>
                <a href="https://enterprisetalk.com/">
                  <img
                    className="imgPublica"
                    style={{ width: "160px", height: "auto" }}
                    src={abcEt}
                    srcSet={`${abcEt} 140w`}
                    sizes="140px"
                    alt="talkmar"
                    width="150"
                    height="35"
                    loading="lazy"
                  />
                </a>
              </>

              <>
                <a href="https://itsecuritywire.com/">
                  <img
                    className="imgPublicaEnter"
                    src={abcIT}
                    srcSet={`${abcIT} 150w`}
                    sizes="150px"
                    alt="talkit"
                    width="150"
                    height="40"
                    loading="lazy"
                  />
                </a>
              </>

              <>
                <a href="https://talkmartech.com/">
                  <img
                    className="imgPublica"
                    style={{ width: "150px", height: "42px", marginTop: "5px" }}
                    src={abcFin}
                    srcSet={`${abcFin} 150w`}
                    sizes="150px"
                    alt="talkfin"
                    width="150"
                    height="40"
                    loading="lazy"
                  />
                </a>
              </>
              <>
                <a href="https://talkcmo.com/">
                  <img
                    className="imgPublicaCMO"
                    src={abcMartech}
                    srcSet={`${abcMartech} 150w`}
                    sizes="150px"
                    alt="talkcmo"
                    width="150"
                    height="40"
                    loading="lazy"
                  />
                </a>
              </>
              <>
                <a href="https://talkdev.com/">
                  <img
                    className="imgPublicadev"
                    src={abcDev}
                    srcSet={`${abcDev} 150w`}
                    sizes="150px"
                    alt="talkdev"
                    width="150"
                    height="40"
                    loading="lazy"
                  />
                </a>
              </>
            </div>

            <div className="mt-3 mb-1 border-top2">
              <p className="mt-2 text-black last-line">
                An Imprint of OnDot ® Media © | All Rights Reserved |{" "}
                <a
                  href="/privacy-policy"
                  className="text-decoration-none hoverHead pRed"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
  
    </>
  );
}